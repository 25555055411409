<template>
  <div class="natural-gas" v-if="yesterdayWeather !== null">
    <div class="info-card">
      <h2><i class="fas fa-burn"></i> Natural Gas</h2>
      <p class="weather-info" v-if="yesterdayWeather !== false">
        <i class="fas fa-thermometer-half"></i> {{ "Yesterday's Weather: " + yesterdayWeather.weather.temp + "°C" }}
      </p>
      <p class="weather-info" v-if="yesterdayWeather !== false">
        <i class="fas fa-calendar-day"></i> {{ "Heating Degree Days: " + heatingDegreeDays }}
      </p>
      <div class="highlighted-info-container" v-if="yesterdayWeather !== false">
        <p class="highlighted-info heating">
          <i class="fas fa-fire"></i> Natural Gas Usage for {{ cityData.name }} for Space Heating: {{ (heatingDegreeDays * 24638).toFixed(0) }} m<sup>3</sup>.
        </p>
        <p class="highlighted-info water">
          <i class="fas fa-industry"></i> Natural Gas Usage for Other Proccess average per day: {{ (238269).toLocaleString() }} m<sup>3</sup>
        </p>
      </div>
      <div class="carbon-emissions-container" v-if="yesterdayWeather !== false">
        <h3><i class="fas fa-cloud"></i> Carbon Emissions for {{ yesterdayDate }}</h3>
        <p class="carbon-info">
          <i class="fas fa-fire"></i> Space Heating: {{ ((heatingDegreeDays * 24638 * 1.932/1000).toFixed(0)) }} tons CO<sub>2</sub>e.
        </p>
        <p class="carbon-info">
          <i class="fas fa-industry"></i> Other Proccess: {{ (238269 * 1.932/1000).toFixed(0).toLocaleString() }} tons CO<sub>2</sub>e.
        </p>
      </div>
      <p class="weather-info" v-else>
        No weather data available for yesterday.
      </p>
    </div>
    <div class="chart-container">
      <iframe :src="weatherChartURL" frameborder="0" vspace="0" hspace="0" marginwidth="0" marginheight="0" scrolling="no" noresize width="850" height="200" class="chart-with-shadow"></iframe>
    </div>
    <div class="chart-container">
      <iframe :src="weatherChartURL2" frameborder="0" vspace="0" hspace="0" marginwidth="0" marginheight="0" scrolling="no" noresize width="850" height="200" class="chart-with-shadow"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "natural-gas",
  computed: {
    ...mapGetters({
      cityData: "city/cityData",
      yesterdayWeather: "city/yesterdayWeather",
      heatingDegreeDays: "city/heatingDegreeDays",
    }),
    weatherChartURL() {
      return `https://${this.cityData.name}.weatherstats.ca/icharts/hdd-daily.html?width=900;height=600`;
    },
    weatherChartURL2() {
      return `https://${this.cityData.name}.weatherstats.ca/icharts/cdd-daily.html?width=900;height=600`;
    },
    yesterdayDate() {
      const date = new Date();
      date.setDate(date.getDate() - 1); // Get yesterday's date
      return date.toLocaleDateString(); // Format date as a readable string
    }
  },
  mounted() {
    if (this.yesterdayWeather === null && this.cityData.id) {
      this.$store.dispatch("city/getYesterdayWeather", this.cityData.id);
    }
    if (this.heatingDegreeDays === null && this.cityData.id) {
      this.$store.dispatch("city/getHeatingDegreeDays", this.cityData.id);
    }
  },
  methods: {
    ...mapActions({
      getYesterdayWeather: "city/getYesterdayWeather",
      getHeatingDegreeDays: "city/getHeatingDegreeDays",
    })
  }
};
</script>


<style scoped>
.city-output {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-card {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.weather-info {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

/* Highlighted information with animation */
.highlighted-info-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.highlighted-info {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  flex: 1;
  margin: 5px;
  animation: highlight 1s ease-in-out infinite alternate;
}

.heating {
  background-color: #fff3e0;
  color: #ff5722;
  animation-name: highlight-heating;
}

.water {
  background-color: #e0f7fa;
  color: #0277bd;
  animation-name: highlight-water;
}

@keyframes highlight-heating {
  0% {
    background-color: #fff3e0;
    color: #ff5722;
  }
  100% {
    background-color: #ffe0b2;
    color: #e64a19;
  }
}

@keyframes highlight-water {
  0% {
    background-color: #e0f7fa;
    color: #0277bd;
  }
  100% {
    background-color: #b3e5fc;
    color: #01579b;
  }
}

/* Carbon emissions styling */
.carbon-emissions-container {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f5f5; /* Updated to a light gray background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carbon-emissions-container h3 {
  font-size: 22px;
  color: #757575; /* Updated to a gray color */
}

.carbon-info {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  color: #757575; /* Updated to a gray color */
}

/* Import FontAwesome styles */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px; /* Added margin bottom */
}

.chart-with-shadow {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 850px;
  height: 200px;
}

@media (max-width: 600px) {
  .chart-with-shadow {
    width: 100%;
    height: auto;
  }
  
  .info-card, .city-output {
    padding: 10px;
  }
  
  .weather-info {
    font-size: 16px;
  }

  .highlighted-info-container {
    flex-direction: column;
  }

  .highlighted-info {
    margin: 5px 0;
  }

  .carbon-emissions-container {
    padding: 10px;
  }

  .carbon-info {
    font-size: 16px;
  }
}
</style>
